<template>
    <b-modal :title="$t('cash.cash')" v-model="modalActive" no-close-on-backdrop :hide-header-close="loading" :no-close-on-esc="loading">
        <template #default>
            <b-overlay :show="loading">
                <validation-observer ref="validation">

                    <div class="w-100 d-flex justify-content-end">
                        <b-form-checkbox v-model="type_of_add" switch @change="resetClient">{{$t('cash.choose_from_already_existing_cash')}}</b-form-checkbox>
                    </div>


                    <div v-if="type_of_add">
                        <b-form-group :label="$t('client.client')">
                            <validation-provider #default="{ errors }" :name="$t('client.client')" rules="required">
                                <v-select :options="clients" :placeholder="$t('client.client')" v-model="client" label="name"  @input="loadCashRegisters" appendToBody>
                                    <template #no-options>
                                        {{$t('table.no_data_to_display')}}
                                    </template>
                                </v-select>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group :label="$t('cash.cash')" v-if="cash_registers && cash_registers.length > 0">
                            <validation-provider #default="{ errors }" :name="$t('cash.cash')" rules="required">
                                <v-select :options="cash_registers" :placeholder="$t('cash.cash')" :reduce="ele => ele.id" v-model="existing_cash_register.cash_register_id" label="cash_register_id" appendToBody>
                                    <template #no-options>
                                        {{$t('table.no_data_to_display')}}
                                    </template>
                                </v-select>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>

                        <validation-provider #default="{ errors }" :name="$t('cash.local_network')" rules="required">
                            <b-form-checkbox v-model="existing_cash_register.local_network" switch>{{$t('cash.cash_register_in_the_local_network')}}</b-form-checkbox>
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>
                    </div>
                    <div v-else>
                        <b-form-group :label="$t('cash.cash_mark')">
                            <validation-provider #default="{ errors }" :name="$t('cash.cash_mark')" rules="required|alpha-num">
                                <b-form-input v-model="object.cash_register_id" placeholder="BGXX"/>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>

                        <b-form-group :label="$t('client.client')">
                            <validation-provider #default="{ errors }" :name="$t('client.client')" rules="required">
                                <v-select :options="clients" :placeholder="$t('client.client')" v-model="client" label="name" @input="loadBusinessUnits" appendToBody>
                                    <template #no-options>
                                        {{$t('table.no_data_to_display')}}
                                    </template>
                                </v-select>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>


                        <b-form-group :label="$t('cash.business_unit')">
                            <validation-provider #default="{ errors }" :name="$t('cash.business_unit')" rules="required">
                                <v-select :options="business_units" :placeholder="$t('cash.business_unit')" :reduce="ele => ele.id" v-model="object.business_unit_id" label="name" appendToBody>
                                    <template #no-options>
                                        {{$t('table.no_data_to_display')}}
                                    </template>
                                </v-select>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>

                        <validation-provider #default="{ errors }" :name="$t('cash.local_network')" rules="required">
                            <b-form-checkbox v-model="object.local_network" switch>{{$t('cash.cash_register_in_the_local_network')}}</b-form-checkbox>
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>
                    </div>

                </validation-observer>
            </b-overlay>
        </template>

        <template #modal-footer>
            <b-button variant="primary" @click="validate" :disabled="loading">
                <span>
                    <feather-icon icon="PlusIcon" class="mr-50"/>
                    <span class="align-middle">{{$t('general.add')}}</span>
                </span>
                <b-spinner v-if="loading" class="ml-1" style="height: 15px; width: 15px"/>
            </b-button>
        </template>
    </b-modal>
</template>
<script>
    import {BButton, BFormGroup, BModal, BFormInput, BFormCheckbox, BSpinner, BOverlay} from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, alphaNum } from '@validations'
    import vSelect from 'vue-select'
    import '@core/scss/vue/libs/vue-select.scss'
    import {captureException} from '@sentry/vue'

    export default {
        components: {
            BOverlay,
            BSpinner,
            BFormInput,
            vSelect,
            BFormGroup,
            BModal,
            BButton,
            ValidationProvider,
            ValidationObserver,
            BFormCheckbox
        },
        data() {
            return {
                loading: false,
                type_of_add: false,
                object: {
                    cash_register_id: '',
                    business_unit_id: '',
                    local_network: true
                },
                business_units: [],
                cashRegister: null,
                modalActive: false,
                client: '',
                id: 0,
                required,
                alphaNum,
                clients: [],
                cash_registers: [],
                existing_cash_register: {
                    cash_register_id: '',
                    business_unit_id: '',
                    local_network: false
                }
            }
        },
        methods: {
            resetClient() {
                this.client = null
                this.object = {
                    cash_register_id: '',
                    business_unit_id: '',
                    local_network: false
                }
                this.existing_cash_register =  {
                    cash_register_id: '',
                    business_unit_id: '',
                    local_network: false
                }
            },
            async loadCashRegisters() {
                try {
                    this.existing_cash_register.cash_register_id = ''
                    this.object.cash_register_id = ''

                    const res = await this.$http.get(`/api/reseller/v1/cash_registers/${this.client.id}`)

                    this.cash_registers = res.data ?? []
                } catch (error) {
                    captureException(error)
                    this.$printError(this.$t('error.on_load_data'))
                }
            },
            async loadBusinessUnits() {
                try {
                    const res = await this.$http.get(`/api/reseller/v1/client/business_units/${this.client.id}`)

                    this.business_units = res.data ?? []
                } catch (error) {
                    captureException(error)
                    this.$printError(this.$t('error.on_load_data'))
                }
            },
            open(data) {
                this.object = {
                    cash_register_id: '',
                    business_unit_id: '',
                    local_network: false
                }
                this.cashRegister = data
                this.loading = false
                this.modalActive = true
                this.loadData()
            },
            validate() {
                this.$refs.validation.validate().then((success) =>  {
                    if (success) {
                        if (this.type_of_add) {
                            this.addExistingCashRegister()
                        } else {
                            this.add()
                        }
                    }
                })
            },
            async loadData() {
                try {
                    const res = await this.$http.get('/api/reseller/v1/client/')
                    this.clients = res.data ?? []
                } catch (error) {
                    captureException(error)
                    this.$printError(this.$t('error.on_load_data'))
                }
            },
            async addExistingCashRegister() {
                try {
                    this.loading = true
                    await this.$http.post(`/api/reseller/v1/cash_registers/reassign/${this.cashRegister.on_premise_token}/${this.client.id}/${this.existing_cash_register.cash_register_id}`, { local_network: this.existing_cash_register.local_network})
                    this.modalActive = false

                    this.existing_cash_register =  {
                        cash_register_id: '',
                        business_unit_id: '',
                        local_network: false
                    }
                    this.type_of_add = false
                    this.$emit('itemAdded')
                    this.$printSuccess(this.$t('success.on_cash_register_adopt'))
                } catch (error) {
                    captureException(error)
                    this.$printError(this.$t('error.on_add'))
                } finally {
                    this.loading = false

                }
            },
            async add() {
                try {
                    this.loading = true
                    await this.$http.post(`/api/reseller/v1/cash_registers/add/${this.cashRegister.on_premise_token}/${this.client.id}`, this.object)
                    this.modalActive = false

                    this.object = {
                        cash_register_id: '',
                        business_unit_id: '',
                        local_network: false
                    }
                    this.$printSuccess(this.$t('success.on_add'))
                    this.$emit('itemAdded')
                    this.$printSuccess(this.$t('success.on_cash_register_adopt'))
                } catch (error) {
                    captureException(error)
                    if (error && error.response && error.response.data && (error.response.data.error === 'cash register id exists in business unit')) {
                        this.$printWarning(this.$t('general.cash_register_id_already_exists'))
                    } else {
                        this.$printError(this.$t('error.on_add'))
                    }
                } finally {
                    this.loading = false
                }
            }
        }
    }
</script>
