var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":_vm.$t('cash.cash'),"no-close-on-backdrop":"","hide-header-close":_vm.loading,"no-close-on-esc":_vm.loading},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('b-overlay',{attrs:{"show":_vm.loading}},[_c('validation-observer',{ref:"validation"},[_c('div',{staticClass:"w-100 d-flex justify-content-end"},[_c('b-form-checkbox',{attrs:{"switch":""},on:{"change":_vm.resetClient},model:{value:(_vm.type_of_add),callback:function ($$v) {_vm.type_of_add=$$v},expression:"type_of_add"}},[_vm._v(_vm._s(_vm.$t('cash.choose_from_already_existing_cash')))])],1),(_vm.type_of_add)?_c('div',[_c('b-form-group',{attrs:{"label":_vm.$t('client.client')}},[_c('validation-provider',{attrs:{"name":_vm.$t('client.client'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.clients,"placeholder":_vm.$t('client.client'),"label":"name","appendToBody":""},on:{"input":_vm.loadCashRegisters},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('table.no_data_to_display'))+" ")]},proxy:true}],null,true),model:{value:(_vm.client),callback:function ($$v) {_vm.client=$$v},expression:"client"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2221526301)})],1),(_vm.cash_registers && _vm.cash_registers.length > 0)?_c('b-form-group',{attrs:{"label":_vm.$t('cash.cash')}},[_c('validation-provider',{attrs:{"name":_vm.$t('cash.cash'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.cash_registers,"placeholder":_vm.$t('cash.cash'),"reduce":function (ele) { return ele.id; },"label":"cash_register_id","appendToBody":""},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('table.no_data_to_display'))+" ")]},proxy:true}],null,true),model:{value:(_vm.existing_cash_register.cash_register_id),callback:function ($$v) {_vm.$set(_vm.existing_cash_register, "cash_register_id", $$v)},expression:"existing_cash_register.cash_register_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3430592898)})],1):_vm._e(),_c('validation-provider',{attrs:{"name":_vm.$t('cash.local_network'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.existing_cash_register.local_network),callback:function ($$v) {_vm.$set(_vm.existing_cash_register, "local_network", $$v)},expression:"existing_cash_register.local_network"}},[_vm._v(_vm._s(_vm.$t('cash.cash_register_in_the_local_network')))]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,835661016)})],1):_c('div',[_c('b-form-group',{attrs:{"label":_vm.$t('cash.cash_mark')}},[_c('validation-provider',{attrs:{"name":_vm.$t('cash.cash_mark'),"rules":"required|alpha-num"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"BGXX"},model:{value:(_vm.object.cash_register_id),callback:function ($$v) {_vm.$set(_vm.object, "cash_register_id", $$v)},expression:"object.cash_register_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('client.client')}},[_c('validation-provider',{attrs:{"name":_vm.$t('client.client'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.clients,"placeholder":_vm.$t('client.client'),"label":"name","appendToBody":""},on:{"input":_vm.loadBusinessUnits},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('table.no_data_to_display'))+" ")]},proxy:true}],null,true),model:{value:(_vm.client),callback:function ($$v) {_vm.client=$$v},expression:"client"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('cash.business_unit')}},[_c('validation-provider',{attrs:{"name":_vm.$t('cash.business_unit'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.business_units,"placeholder":_vm.$t('cash.business_unit'),"reduce":function (ele) { return ele.id; },"label":"name","appendToBody":""},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('table.no_data_to_display'))+" ")]},proxy:true}],null,true),model:{value:(_vm.object.business_unit_id),callback:function ($$v) {_vm.$set(_vm.object, "business_unit_id", $$v)},expression:"object.business_unit_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('validation-provider',{attrs:{"name":_vm.$t('cash.local_network'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.object.local_network),callback:function ($$v) {_vm.$set(_vm.object, "local_network", $$v)},expression:"object.local_network"}},[_vm._v(_vm._s(_vm.$t('cash.cash_register_in_the_local_network')))]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1)]},proxy:true},{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"primary","disabled":_vm.loading},on:{"click":_vm.validate}},[_c('span',[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('general.add')))])],1),(_vm.loading)?_c('b-spinner',{staticClass:"ml-1",staticStyle:{"height":"15px","width":"15px"}}):_vm._e()],1)]},proxy:true}]),model:{value:(_vm.modalActive),callback:function ($$v) {_vm.modalActive=$$v},expression:"modalActive"}})}
var staticRenderFns = []

export { render, staticRenderFns }